import { useMemo, useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';

//* Style
import GallerySectionStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import Image from '@/components/common/Image';
import Button from '@/components/common/Button';

//* Helpers
import { useActiveSection } from '@/helpers';
import ScrollActive from '@/components/common/ScrollActive';
import { withDataContext, withLanguageContext } from '@/context';

const GallerySection = ({ items, globalData, selectedLang }) => {
	//! Refs
	const slidesRef = useRef();
	const titleRef = useRef();
	const sectionRef = useRef();

	//! States
	const [activeIndex, setActiveIndex] = useState(0);

	//! Active section
	const activeSection = useActiveSection(sectionRef);

	//! All images
	const storeAllImages = useMemo(() => {
		if (items) {
			return items.map((slide, index) => {
				const prevClass = activeIndex > index ? 'prev-slide' : '';
				const nextClass = activeIndex < index ? 'next-slide' : '';

				return (
					<Image
						key={index}
						className={`slide ${activeIndex === index ? 'active' : ''} ${prevClass} ${nextClass}`}
						src={slide.url}
						alt={slide.alt}
					/>
				);
			});
		}
	}, [items, activeIndex]);

	useEffect(() => {
		if (activeSection) {
			gsap.to(slidesRef.current, { scale: 1, duration: 1, ease: 'power3.easeOut' });
		}
	}, [activeSection]);

	//! Autoplay
	const intervalRef = useRef();

	useEffect(() => {
		if (items.length > 2) {
			const index = items.length - 1 < activeIndex + 1 ? 0 : activeIndex + 1;
			if (intervalRef.current) {
				clearTimeout(intervalRef.current);
			}

			intervalRef.current = setTimeout(() => {
				setActiveIndex(index);
			}, 3000);
		}
	}, [activeIndex]);

	return (
		<GallerySectionStyle ref={sectionRef}>
			<Container>
				<div className='gallery-title-wrap'>
					<ScrollActive
						animTitle
						allRefs={titleRef}
						typeWordLines={`lines`}>
						<Text
							ref={titleRef}
							className={'h1 font-montserrat-medium gallery-title opacity-0'}>
							{globalData[selectedLang].gallery_title}
						</Text>
					</ScrollActive>
				</div>
				<div className='slides-container'>
					<div
						className='slides-wrap'
						ref={slidesRef}>
						{storeAllImages}
					</div>

					{items ? (
						items.length > 1 ? (
							<>
								<Button
									onClick={() => setActiveIndex(activeIndex - 1)}
									btnType={'frame'}
									isFullRadius
									isDisabled={activeIndex === 0}
									className={'prev-button'}
								/>
								<Button
									onClick={() => setActiveIndex(activeIndex + 1)}
									btnType={'frame'}
									isFullRadius
									isDisabled={activeIndex === items.length - 1}
									className={'next-button'}
								/>
							</>
						) : null
					) : null}
				</div>
			</Container>
		</GallerySectionStyle>
	);
};

export default withLanguageContext(withDataContext(GallerySection, ['globalData']), ['selectedLang']);
