import { useRef, useCallback, useState, useEffect } from 'react';
import { ScrollTrigger, SplitText } from 'gsap/all';
import { gsap } from 'gsap';
import { usePathname } from 'next/navigation';

//* HOC's
import { withUIContext, withLanguageContext, withDataContext } from '@/context';

//* Style
import CallToActionStyle from './style';

//* Components
import PopupRequestCallOrReservation from '../PopupRequestCallOrReservation';
import Container from '@/components/common/Container';
import Text from '@/components/common/Text';
import Resize from '../ScrollActive/Resize';

const CallToAction = ({ openPopup, isTouch, globalData, selectedLang }) => {
	//! States
	const [resize, setResize] = useState(false);

	//! Next Router
	const pathname = usePathname();

	//! Refs
	const cursorRef = useRef();
	const contRef = useRef();
	const sectionRef = useRef();
	const typingText = useRef();
	const refsSplit = useRef();
	const isAnimationLoaded = useRef();
	const scrollTrigger = useRef();

	//! Handle mouse move
	const handleMouseMove = useCallback(
		(e) => {
			if (!isTouch) {
				const cursorContainer = contRef.current.getBoundingClientRect();

				const x = e.clientX - cursorContainer.left;
				const y = e.clientY - cursorContainer.top;

				gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: x, top: y, scale: 1 });
			}
		},
		[isTouch]
	);

	//! Handle mouse leave
	const handleMouseLeave = useCallback(() => {
		if (!isTouch) {
			gsap.to(cursorRef.current, { duration: 0.3, opacity: 0, scale: 0 });
		}
	}, [isTouch]);

	//! Refresh cursor Page
	useEffect(() => {
		isAnimationLoaded.current = false;
		gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: '50%', top: '50%', scale: 1 });
	}, [pathname]);

	useEffect(() => {
		if (!isAnimationLoaded.current) {
			if (typingText.current) {
				gsap.to(typingText.current, { opacity: 0, duration: 0 });
			}

			scrollTrigger.current = ScrollTrigger.create({
				trigger: typingText.current,
				start: `top 80%`,
				end: 'top top',
				// markers: true,
				id: 'call-action',
				once: true,

				onEnter: () => {
					isAnimationLoaded.current = true;
					refsSplit.current = new SplitText(typingText.current, { type: 'chars,words' });
					const chars = refsSplit.current.chars;

					gsap.to(typingText.current, {
						opacity: 1,
						duration: 0,
					});

					gsap.from(chars, {
						duration: 0.6,
						opacity: 1,
						autoAlpha: 0,
						ease: 'power2.out',
						stagger: 0.05,
					}).then(() => {
						if (refsSplit.current) {
							// refsSplit.current.revert();
							gsap.to(typingText.current, { opacity: 1, duration: 0 });
						}
					});
				},
			});
		}

		return () => {
			if (!isAnimationLoaded.current) {
				if (refsSplit.current) refsSplit.current.revert();
				if (scrollTrigger.current) scrollTrigger.current.kill();
			}
		};
	}, [resize, pathname]);

	function clickPopupButton() {
		openPopup(<PopupRequestCallOrReservation />);
	}

	return (
		<Resize setResize={setResize}>
			<CallToActionStyle ref={sectionRef}>
				{globalData[selectedLang]?.info && (
					<Container>
						<div
							onClick={clickPopupButton}
							className='call-to-action dark-red-bg'
							onMouseMove={handleMouseMove}
							onMouseLeave={handleMouseLeave}
							ref={contRef}>
							{!isTouch && (
								<div
									ref={cursorRef}
									className={`call-to-action-drag-container`}>
									<Text
										tag={`span`}
										className={`h5 font-montserrat-medium call-to-action-drag`}
										text={`requestACall`}
									/>
								</div>
							)}
							<div className='upper-text-wrap'>
								<Text
									className={'h1 white upper-text font-montserrat-medium opacity-0'}
									ref={typingText}>
									{globalData[selectedLang].info}
								</Text>
							</div>
							<div className='static-cursor'>
								<Text
									tag={`span`}
									className={`h5 font-montserrat-medium call-to-action-drag`}
									text={`requestACall`}
								/>
							</div>
						</div>
					</Container>
				)}
			</CallToActionStyle>
		</Resize>
	);
};

export default withLanguageContext(withDataContext(withUIContext(CallToAction, ['winWidth', 'toggleBurgerMenu', 'isBurgerMenuOpen', 'closeBurgerMenu', 'openPopup']), ['globalData']), ['selectedLang']);
