import styled from 'styled-components';

const HeroSectionStyle = styled.div`
	--heroSectionMargin: var(--sp4x) var(--sp4x) var(--sp34x) var(--sp4x);

	margin: var(--heroSectionMargin);
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	will-change: transform;

	.hero {
		background-color: transparent;
		min-height: calc(970 / 1920 * 100vw);

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.wrapper-hero {
		clip-path: inset(0% 0% 100% 0%);
	}

	:nth-child(2) {
		&.wrapper-item {
			left: 18%;
			top: 42%;
		}
	}

	:nth-child(3) {
		&.wrapper-item {
			left: 44%;
			top: 42%;
		}
	}

	:nth-child(4) {
		&.wrapper-item {
			left: 56%;
			top: 58%;
		}
	}

	:nth-child(5) {
		&.wrapper-item {
			left: 69%;
			top: 65%;
		}
	}

	:nth-child(6) {
		&.wrapper-item {
			left: 85%;
			top: 77%;
		}
	}
	:nth-child(7) {
		&.wrapper-item {
			left: 33%;
			top: 55%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--heroSectionMargin: var(--sp3x) var(--sp3x) var(--sp28x) var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp22x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp22x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp15x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp12x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp12x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--heroSectionMargin: var(--sp1-5x) var(--sp1-5x) var(--sp9x) var(--sp1-5x);

		/* min-height: calc(970 / 1920 * 100vw); */

		.hero {
			min-height: calc(110vw / (1920 / 970));

			.inner-svg-wrapper {
				position: relative;
			}
			svg {
				position: relative;
				left: 50%;
				transform: translateX(-51%);
				width: 110vw;
			}
		}
		:nth-child(2) {
			&.wrapper-item {
				left: 10%;
				top: 43%;
			}
		}

		:nth-child(3) {
			&.wrapper-item {
				left: 45%;
				top: 40%;
			}
		}

		:nth-child(4) {
			&.wrapper-item {
				left: 57%;
				top: 55.5%;
			}
		}

		:nth-child(5) {
			&.wrapper-item {
				left: 72%;
				top: 62.5%;
			}
		}

		:nth-child(6) {
			&.wrapper-item {
				left: 88.5%;
				top: 73%;
			}
		}
		:nth-child(7) {
			&.wrapper-item {
				left: 28%;
				top: 51%;
			}
		}
	}
`;

export default HeroSectionStyle;
