import { useState, useRef, useCallback } from 'react';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Style
import PopupRequestCallStyle from './style';

//* Components
import Text from '@/components/common/Text';
import FormContainer from '@/components/common/Form/FormContainer';
import FormItem from '@/components/common/Form/FormItem';
import FormInput from '@/components/common/Form/FormInput';
import Container from '@/components/common/Container';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import Image from "@/components/common/Image";

const PopupRequestCall = ({ translate, contactUs, status, buildingId, apartmentId, type, building, apartmentName }) => {
	//! Refs
	const form = useRef();

	//! States
	const [isSuccess, setIsSuccess] = useState(false);
	const [accept, setAccept] = useState(type !== 'reserve');

	const handleAcceptChange = useCallback(
		(e) => {
			setAccept(e.target.checked);
		},
		[accept]
	);

	//! Handle Finish
	const onFinish = async (values) => {
		setIsSuccess('loading');

		return await contactUs({ ...values, ...(buildingId && { building_id: buildingId }), ...(apartmentId && { apartment_id: apartmentId }), ...(status ? { status: 'reservation' } : { status: 'call' }) })
			.then(() => {
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
				}, 3000);
			})
			.catch((err) => {
				setIsSuccess(false);
				return Promise.reject(err);
			});
	};

	return (
		<PopupRequestCallStyle>
			{type == 'reserve' ? (
				<div className='wrapper-info-top-reservation'>
					<Text
						className={'h3 font-montserrat-medium title'}
						text={'reserveYourApartment'}
					/>
					<p className="comp-text p1 font-montserrat popup-description">{translate('reserveDescription')}</p>
					<div className='wrapper-reserve'>
						<div className='building-apartment'>
							<Image height={14} width={12} src={'/images/building.svg'} />
							<div className="building-apartment-divider"/>
							<Text className={'p1 font-montserrat text-info-reservation'}>
								{building}
							</Text>
						</div>
						<div className='building-apartment'>
							<Image height={14} width={14} src={'/images/apartment.svg'} />
							<div className="building-apartment-divider"/>
							<Text className={'p1 font-montserrat text-info-reservation'}>
								{'#'}{apartmentName.substring(1)}
							</Text>
						</div>
					</div>
				</div>
			) : (
				<div className="wrapper-info-top-request">
					<Text
						className={'h3 font-montserrat-medium title'}
						text={'requestACall'}
					/>
					<p className="comp-text p1 font-montserrat popup-description">{translate('requestDescription')}</p>
				</div>
			)}

			<FormContainer
				ref={form}
				onFinish={onFinish}>
				<Container
					row
					full>
					<div className='col-6 col-m-12 everyInput'>
						<FormItem name={'first_name'}>
							<FormInput
								uppercase
								placeholder={translate('firstName')}
							/>
						</FormItem>
					</div>

					<div className='col-6 col-m-12 everyInput'>
						<FormItem
							name={'last_name'}
							required={false}>
							<FormInput
								uppercase
								placeholder={translate('lastName')}
							/>
						</FormItem>
					</div>

					{type === 'reserve' && <div className='col-6 col-m-12 everyInput'>
						<FormItem
							name={'email'}
							required={false}
							customMessage={true}
							rules={[{type: 'email', message: translate('invalidEmailAddress')}]}>
							<FormInput placeholder={translate('email')}/>
						</FormItem>
					</div>}

					<div className={`col-6 col-m-12 ${type === 'reserve' ?'everyInput':''}`}>
						<FormItem name={'phone'}>
							<FormInput
								isPhoneInput
								placeholder={translate('phone')}
							/>
						</FormItem>
					</div>
				</Container>

				{type === 'reserve' && <Checkbox
					accept={accept}
					onChange={handleAcceptChange}
					checkboxText={translate('checkboxText')}
				/>}

				<div className={`submit-and-success-wrap ${isSuccess ? 'success' : ''}`}>
					{isSuccess && isSuccess !== 'loading' ? (
						<Text
							className={'h6 font-montserrat-bold main-green'}
							text={'requestCallSuccessMessage'}
						/>
					) : (
						<Button
							type={'submit'}
							colorType={'red'}
							backgroundColor={'green'}
							disabled={!accept || isSuccess}
							className={'send-request-button'}
							isDisabled={!accept || isSuccess}
							text={isSuccess === 'loading' ? `${translate('loading')}...` : translate(type === 'reserve' ? 'reserve' : 'request')}
						/>
					)}
				</div>
			</FormContainer>
		</PopupRequestCallStyle>
	);
};

export default withDataContext(withLanguageContext(PopupRequestCall, ['translate']), ['contactUs']);
