import styled from 'styled-components';

const PopupRequestCallStyle = styled.div`
	--titleMarginBottom: var(--sp10x);
	--sendRequestButtonMarginTop: var(--sp5x);
	--tagPadding: var(--sp3x) var(--sp5x);
	--tagMarginRight: var(--sp4x);
	--tagsWrapMarginTopBottom: var(--sp4x) 0 var(--sp10x) 0;
	--inputDistance: var(--sp10x);
	--reserveInfoPadTB: var(--sp3x);
	--reserveInfoPadLR: var(--sp5x);
	--distanceInfoReservation: var(--sp5x);
	--radiusInfo: calc(var(--sp1x) + var(--sp0-5x) / 2);
	--distanceInputReservation: var(--sp4x);
	--distanceTitleReservation: var(--sp2x);
	--popupDescriptionTop: var(--sp1x);
    --popupDescriptionBottom: var(--sp5x);
    --popupReservationDescriptionBottom: var(--sp2x);
    --popupReservationDividerHeight: 26px;
    --popupReservationDividerWidth: 1px;
    --sizeTextFont: 18px;

	&.reserve {
		.title {
			margin-bottom: unset;
		}
	}

	.reserve-info-wrap {
		display: flex;
		justify-content: center;
		margin: var(--tagsWrapMarginTopBottom);

		.tag {
			background-color: var(--background);
			padding: var(--tagPadding);
			width: fit-content;
			border-radius: var(--sp1x);
			margin-right: var(--tagMarginRight);

			&.apartment {
				margin-right: unset;
			}
		}
	}

	.submit-and-success-wrap {
		margin-top: var(--sendRequestButtonMarginTop);

		display: flex;
		align-items: center;
		justify-content: flex-end;

		.send-request-button {
			margin-left: auto;
			width: fit-content;
		}

		&.success {
			justify-content: space-between;
		}
	}

	.ant-input {
		background-color: transparent !important;
	}

	.title {
		text-align: center;
	}
    .popup-description {
        margin-top: var(--popupDescriptionTop);
        margin-bottom: var(--popupDescriptionBottom);
        color: rgb(142, 142, 142);
        line-height: var(--lineHeight2);
    }

	.everyInput {
		padding-bottom: var(--inputDistance);
	}

    .wrapper-info-top-request {
        display: flex;
        flex-direction: column;
    }
    
	.wrapper-info-top-reservation {
		display: flex;
		flex-direction: column;

		.wrapper-reserve {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: var(--distanceInfoReservation);
			margin-top: var(--distanceTitleReservation);
			margin-bottom: var(--distanceInputReservation);
		}
        
        .popup-description {
            margin-bottom: var(--popupReservationDescriptionBottom);
        }

		.building-apartment {
            display: flex;
            align-items: center;
            gap: 7px;
            
            .image-cont {
                padding-top: 0;
            }
		}
        
        .building-apartment-divider {
            height: var(--popupReservationDividerHeight);
            width: var(--popupReservationDividerWidth);
            background-color: rgb(217, 217, 217);
        }

		.text-info-reservation {
			color: var(--black1000);
            font-size: var(--sizeTextFont)
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--titleMarginBottom: var(--sp10x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp4x);
		--tagMarginRight: var(--sp3x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp8x) 0;
		--inputDistance: var(--sp8x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp4x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp3x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarginBottom: var(--sp6x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp6x) 0;
		--inputDistance: var(--sp5x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp3x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp2-5x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarginBottom: var(--sp6x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp6x) 0;
		--inputDistance: var(--sp5x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp3x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp2-5x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp5x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp3x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp2x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp5x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp3x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp2x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp5x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp3x);
		--distanceInfoReservation: var(--sp5x);
		--distanceInputReservation: var(--sp2x);
		--distanceTitleReservation: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleMarginBottom: var(--sp4x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp2-5x);
		--tagMarginRight: var(--sp1-5x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp4x) 0;
		--inputDistance: var(--sp4x);

		--reserveInfoPadTB: var(--sp2x);
		--reserveInfoPadLR: var(--sp2-5x);
		--distanceInfoReservation: var(--sp1-5x);
		--distanceInputReservation: var(--sp1-5x);
		--distanceTitleReservation: var(--sp1x);
	}
`;

export default PopupRequestCallStyle;
