import { useMemo, useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';

//* Style
import AboutProjectSectionStyle from './style';

//* Components
import Container from '@/components/common/Container';
import Text from '@/components/common/Text';
import AboutProjectItem from './AboutProjectItem';
import ScrollActive from '@/components/common/ScrollActive';

const AboutProjectSection = ({ title, description, items }) => {
	//! Refs
	const sectionRef = useRef();
	const descriptionRef = useRef();
	const titleRef = useRef();

	//! State
	const [percentD, setPercentD] = useState();

	useEffect(() => {
		if (percentD) {
			[...descriptionRef.current.children].map((item) => {
				gsap.fromTo(
					item,
					{ y: '100' },
					{
						duration: 1.6,
						ease: 'power2.out',
						y: 0,
						delay: 0.1,
					}
				);
			});
		}
	}, [percentD]);

	//! About items
	const storeAboutItems = useMemo(() => {
		return items.map((item, index) => {
			return (
				<div
					key={index}
					className='col-6 col-m-12 about-item-col'>
					<AboutProjectItem
						className={`${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}
						{...item}
					/>
				</div>
			);
		});
	}, [items]);

	return (
		<AboutProjectSectionStyle ref={sectionRef}>
			<Container
				row
				className={'about-content-wrap'}>
				<div className='col-2 col-t-1 col-m-12 offset'></div>
				<div className='col-8 col-t-9 col-m-12'>
					<div className='section-title'>
						<ScrollActive
							animTitle
							allRefs={titleRef}
							typeWordLines={`lines`}>
							<Text
								ref={titleRef}
								tag={'h1'}
								className={'h2 font-montserrat-medium main-blue title opacity-0'}>
								{title}
							</Text>
						</ScrollActive>
					</div>
					<div className='section-description'>
						<div className='wrapper-description'>
							<ScrollActive
								animTitle
								markerContainer={titleRef}
								allRefs={descriptionRef}
								typeWordLines={`lines`}
								percent
								percentDuration={40}
								stagger={0.15}
								duration={1}
								percentFunction={setPercentD}>
								<Text
									ref={descriptionRef}
									className={'h4 font-montserrat main-blue description opacity-0'}>
									{description}
								</Text>
							</ScrollActive>
						</div>
					</div>
				</div>
			</Container>
			<Container
				row
				className={'about-items-container'}>
				{storeAboutItems}
			</Container>
		</AboutProjectSectionStyle>
	);
};

export default AboutProjectSection;
