import { useMemo, useRef, useCallback, useEffect } from 'react';

//* Style
import ScrollableFilterBarStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Container from '../Container';
import gsap from 'gsap/all';
import { Draggable } from 'gsap/Draggable';
import { withUIContext } from '@/context';

const ScrollableFilterBar = ({ filters, className, activeFilter, setActiveFilter, winWidth, vertical }) => {
	//! Refs
	const filtersWrapRef = useRef();

	const onWheel = useCallback(
		(e) => {
			e.preventDefault();
			let [horizontal, vertical] = [e.deltaX, e.deltaY];
			let magnitude;

			if (horizontal === 0) {
				magnitude = vertical > 0 ? -10 : 10;
			} else {
				magnitude = horizontal;
			}

			filtersWrapRef.current.scrollBy({ left: magnitude });
		},
		[filtersWrapRef]
	);

	useEffect(() => {
		filtersWrapRef.current?.addEventListener('wheel', onWheel);

		return () => {
			filtersWrapRef.current?.removeEventListener('wheel', onWheel);
		};
	}, []);

	//! filters
	const storeFilters = useMemo(() => {
		return filters?.map((filter, index) => {
			return (
				<div
					className={`filter-item ${activeFilter === filter.id ? 'active' : ''}`}
					key={index}
					onClick={() => setActiveFilter(filter.id)}>
					<div className={`name`}>
						<Text className={'h5 font-montserrat-medium content-text'} text={filter.name} />
						<div className='layer' />
					</div>
				</div>
			);
		});
	}, [filters, activeFilter]);
	//! Ref
	const draggableInstance = useRef();
	const wrapperBounds = useRef();

	useEffect(() => {
		const allWidth = wrapperBounds.current.getBoundingClientRect().width;
		const innerContentWidth = filtersWrapRef.current.clientWidth;

		gsap.registerPlugin(Draggable);

		if (allWidth < innerContentWidth) {
			draggableInstance.current = Draggable.create(filtersWrapRef.current, {
				type: 'x',
				bounds: wrapperBounds.current,
				inertia: true,
			});
		}

		return () => {
			if (draggableInstance.current) {
				draggableInstance.current.forEach((instance) => instance.kill());
			}
		};
	}, [winWidth]);

	return (
		<ScrollableFilterBarStyle className={className || ''}>
			<Container className={`${vertical ? 'full' : ''}`}>
				<div className={'container-wrapper'}>
					<div
						ref={wrapperBounds}
						className='wrapper-bounds'>
						<div
							ref={filtersWrapRef}
							className={`filters-wrap ${vertical ? 'filters-wrap-vertical' : ''}`}>
							{storeFilters}
						</div>
					</div>
				</div>
			</Container>
		</ScrollableFilterBarStyle>
	);
};

export default withUIContext(ScrollableFilterBar, ['winWidth']);
