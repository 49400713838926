// import { forwardRef, useState, useRef, useImperativeHandle } from 'react';

// //* HOC's
// import { withUIContext } from '@/context';

// //* Style
// import VideoStyle from './style';

// //* Components
// import Icon from '../Icon';

// const Video = forwardRef(({ isIcon = true, winWidth, double, ...props }, ref) => {
// 	//! Ref
// 	const videoRef = useRef();

// 	//! Imperative Handle
// 	useImperativeHandle(ref, () => videoRef.current, []);

// 	//! Mobile Desktop
// 	const doubleSrc = double ? (winWidth >= 1280 ? double?.[0]?.src : double?.[1]?.src) : null;

// 	//! States
// 	const [isPlaying, setIsPlaying] = useState(false);
// 	const [showControls, setShowControls] = useState(true);

// 	const togglePlay = () => {
// 		const element = videoRef.current;

// 		const video = element.querySelector('video');

// 		if (!video) return;

// 		if (video.paused || video.ended) {
// 			video.play();

// 			setIsPlaying(true);
// 			setShowControls(false);
// 		} else {
// 			video.pause();

// 			setIsPlaying(false);
// 			setShowControls(true);
// 		}
// 	};

// 	const handleMouseEnter = () => {
// 		setShowControls(true);

// 		//! Video auto-stop
// 		const element = videoRef.current;
// 		const video = element.querySelector('video');

// 		video?.addEventListener('ended', function () {
// 			setIsPlaying(false);
// 			setShowControls(true);
// 		});
// 	};

// 	const handleMouseLeave = () => {
// 		isPlaying && setShowControls(false);
// 	};

// 	return (
// 		<VideoStyle
// 			ref={videoRef}
// 			onMouseEnter={handleMouseEnter}
// 			onMouseLeave={handleMouseLeave}
// 			className={`video-cont ${props.className || ''}`}>
// 			<video
// 				playsInline
// 				muted={false}
// 				autoPlay={false}
// 				loop={props.loop}
// 				preload={`metadata`}
// 				controls={props.controls}
// 				onClick={props.handleClick}>
// 				<source
// 					src={`${props?.src || doubleSrc}#t=0.1`}
// 					type='video/mp4'
// 				/>

// 				<source
// 					src={`${props?.src || doubleSrc}#t=0.1`}
// 					type='video/webm'
// 				/>
// 			</video>

// 			{isIcon && showControls ? (
// 				<Icon
// 					onClick={togglePlay}
// 					className={'video-icon'}
// 					name={isPlaying ? 'pause-circle' : 'play-circle'}
// 				/>
// 			) : null}
// 		</VideoStyle>
// 	);
// });

// Video.displayName = 'Video';

// export default withUIContext(Video, ['winWidth']);

import { forwardRef, useEffect, useState, useRef, useImperativeHandle } from 'react';

//* Style
import VideoStyle from './style';

const Video = forwardRef((props, ref) => {
	//! State
	const [check, setCheck] = useState(true);

	//! Ref
	const videoRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => videoRef.current, []);

	useEffect(() => {
		if (!props.autoPlay) {
			setCheck(props.autoPlay);
		}
	}, [props.autoPlay]);

	return (
		<VideoStyle
			ref={videoRef}
			className={`video-cont ${props.className || ''}`}>
			<video
				playsInline
				muted={check}
				autoPlay={check}
				loop={props.loop}
				preload={`metadata`}
				controls={props.controls}
				onClick={props.handleClick}
				className={props.videoIndex}
				poster={'/images/videoPreview.png'}
			>
				<source
					src={`${props.src}`}
					type='video/mp4'
				/>

				<source
					src={`${props.src}`}
					type='video/webm'
				/>
			</video>
		</VideoStyle>
	);
});

Video.displayName = 'Video';

export default Video;
